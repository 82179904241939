<template>
  <div class="bg-black-0 rounded py-3 px-16 px-sm-32 mx-0">
    <h2>{{ $route.name === 'detail-master-data-rs' ? 'Detail' : 'Edit' }} Rumah Sakit</h2>
    <hr class="w-100">
    <b-row>
      <b-col
        cols="6"
        class="mb-3"
      >
        <b-form-group
          label="Nama Rumah Sakit"
          label-for="name-rs"
        >
          <p
            v-if="routeName"
            class="hp-p1-body mb-0"
          >
            {{ formData.name }}
          </p>
          <b-form-input
            v-else
            id="name-rs"
            v-model="formData.name"
            placeholder="Masukkan Nama Rumah Sakit"
            type="text"
          />
        </b-form-group>
        <b-form-group
          label="Kontak Rumah Sakit"
          label-for="contact-rs"
        >
          <p
            v-if="routeName"
            class="hp-p1-body mb-0"
          >
            {{ formData.contact }}
          </p>
          <b-form-input
            v-else
            id="contact-rs"
            v-model="formData.contact"
            placeholder="Masukkan Kontak Rumah Sakit"
            type="text"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        class="mb-3"
      >
        <b-form-group
          label="Alamat Rumah Sakit"
          label-for="address-rs"
        >
          <p
            v-if="routeName"
            class="hp-p1-body mb-0"
          >
            {{ formData.address }}
          </p>
          <google-maps
            v-else
            v-model="formData.address"
            @address-extracted="handlePlaceSelected"
          />
        </b-form-group>

        <b-form-group
          label="Email Rumah Sakit"
          label-for="email-address-rs"
        >
          <p
            v-if="routeName"
            class="hp-p1-body mb-0"
          >
            {{ formData.email }}
          </p>
          <b-form-input
            v-else
            id="email-address-rs"
            v-model="formData.email"
            placeholder="Masukkan Email Rumah Sakit"
            type="email"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="!routeName"
        class="d-flex justify-content-end mb-3"
      >
        <b-button
          variant="danger"
          class="mr-3"
          @click="$router.go(-1)"
        >Batal</b-button>

        <b-button
          :disabled="!isFormValid"
          variant="primary"
          class="mr-3"
          @click="postUpdateHospital"
        >Simpan</b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BButton, BInputGroup, BInputGroupPrepend, BFormInput, BSpinner, BForm, BFormGroup,
} from 'bootstrap-vue'
import GoogleMaps from '@/components/maps/GoogleMaps.vue'
import manageHospitalAPI from '../../../../api/managehospital/manageHospitalAPI'

export default {
  name: 'DetailHospital',
  components: {
    BButton, BInputGroup, BInputGroupPrepend, BFormInput, BSpinner, BForm, BFormGroup, GoogleMaps,
  },
  data() {
    return {
      isBusy: false,
      formData: {
        name: '',
        address: '',
        contact: '',
        email: '',
      },
    }
  },
  computed: {
    routeName() {
      return this.$route.name === 'detail-master-data-rs'
    },
    isFormValid() {
      return (
        this.formData.name
          && this.formData.address
          && this.formData.email
          && this.formData.contact
      )
    },
  },
  created() {
    this.fetchDetail()
  },
  methods: {
    async fetchDetail() {
      await manageHospitalAPI.getDetailRS(this.$route.params.id).then(response => {
        this.formData.name = response.data.data.name
        this.formData.address = response.data.data.address
        this.formData.contact = response.data.data.phone
        this.formData.email = response.data.data.email ?? 'Email Tidak Tersedia'
      })
    },
    async postUpdateHospital() {
      const formData = new FormData()

      formData.append('id', this.$route.params.id)
      formData.append('name', this.formData.name)
      formData.append('phone', this.formData.contact)
      formData.append('address', this.formData.address)
      formData.append('email', this.formData.email)

      await manageHospitalAPI.updateRSDropping(formData).then(response => {
        this.$router.push({ name: 'list-master-data-rs' })

        this.$bvToast.toast('Data berhasil disimpan', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      }).catch(e => {
        this.$bvToast.toast('Data Gagal disimpan', {
          title: 'Gagal',
          variant: 'danger',
          solid: true,
        })
      })
    },
    handlePlaceSelected(place) {
      this.formData.address = place.formattedAddress
    },
  },
}
</script>
